
<template>
  <div class="index">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div style="padding-bottom: 20px;margin: 0">
        <van-list :error.sync="error"  error-text="请求失败，点击重新加载" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" ref="scrollNews">
          <div v-for="(item,index) in infoList" :key="index">
            <pendingTaskCell :info="item" @click.native="clickNews(item)" :myUniqueNo="myUniqueNo" :flag="flag" :stationNo="item.stationNo"></pendingTaskCell>
          </div>
        </van-list>
      </div>
    </van-pull-refresh>
    <baidu-map style="display: none"
               @ready="onBaiduMapReady">
    </baidu-map>
    <van-dialog v-model="dialogShow" title="提示" confirmButtonText="知道了" confirmButtonColor="#3F7C53" cancelButtonColor="#B5BBC9">
      <div style="margin:0.2rem;text-indent: 0.5rem;color: #333333; font-size:0.3rem">
        你现在距离该服务站较远，不能进行巡检任务。如需修改站点定位，可至站点基本信息中修正定位。
      </div>
    </van-dialog>
  </div>
</template>

<script>

  import pendingTaskCell from "./pendingTaskCell";
  import { getMyTaskList } from "@/utils/api";
  import common from "@/utils/common";
  import { getStationInfoByNo,getDistance} from '@/utils/api';

  export default {
    name: "pendingTask",
    components: { pendingTaskCell },
    props: ['active', 'flag','targetDate', 'conditions','deptNo'],
    watch: {
      active (newValue) {
        if (newValue == 0) {
          this.toSetScrollTop()
          this.infoList = []
          this.pageIndex= 1
          this.toGetMyTaskList()
        }
      },
      flag(){
        if(this.active == 0){
          this.infoList = []
          this.pageIndex= 1
          this.toGetMyTaskList()
        }
      },
      targetDate(newValue){
        if(this.active == 0){
          this.infoList = []
          this.pageIndex= 1
          this.toGetMyTaskList()
        }
      },
      deptNo(){
        if(this.active == 0){
          this.infoList = []
          this.pageIndex= 1
          this.toGetMyTaskList()
        }
      },
      conditions(val) {
        console.log('val:', val)
        this.toGetMyTaskList()
      }
    },
    data () {
      return {
        topList: [],
        infoList: [],
        isLoading: false,
        loading: false,
        finished: false,
        pageIndex: 1,
        pageSize: '10',
        error: false,
        listHeight: null,
        scrollTop: 0,
        pointA:{},
        BMap:'',
        distance:0,
        dialogShow:false,
        isIOS:false,
        myUniqueNo:''
      }
    },
    activated () {
    },
    deactivated () {
      window.removeEventListener('scroll', this.handleScroll, true);
    },
    mounted () {
      window.addEventListener("scroll", this.handleScroll, true);
      this.myUniqueNo = common.getItem('uniqueNo');
      this.isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll, true)
    },
    methods: {
      //地图初始化
      onBaiduMapReady(e) {
        const that = this
        that.BMap = e.BMap
        if (that.BMap) {
          const geolocation = new that.BMap.Geolocation()
          geolocation.enableSDKLocation();
          // 通过 getCurrentPosition() 方法：获取当前的位置信息
          geolocation.getCurrentPosition(res => {
            const { lng, lat } = res.point
            that.pointA = { lng, lat }
          })
        }
      },

      handleScroll () {
        this.$nextTick(() => {
          if (this.$refs.scrollNews.scroller.scrollTop != undefined) {
            if (this.active == 0) {
              this.scrollTop = this.$refs.scrollNews.scroller.scrollTop
            }
          }
        })
      },
      toSetScrollTop () {
        this.$refs.scrollNews.scroller.scrollTop = this.scrollTop
      },
      clickNews (item) {
        getStationInfoByNo({stationNo: item.stationNo}).then(res=>{
          if(res.code === 200 && res.data.businessCode ===200){
            let distance = getDistance(this.pointA.lat,this.pointA.lng,res.data.data.lat,res.data.data.lng);
            if(item.taskCode === 'inspectTask'){// 是巡检任务
              console.log("lat1:",this.pointA.lat)
              console.log("lng1:",this.pointA.lng)
              if(!this.isIOS && distance > 1000){
                this.dialogShow = true;
              }else{
                let routerName
                if (item.taskCode === 'inspectTask') routerName = 'qcInfoList' // 巡检
                if (item.taskCode === 'stationStandardization') routerName = 'standardTaskList' // 标准化
                this.$router.push({ name: routerName, query: { 'taskNo': item.taskNo, 'stationNo': item.stationNo,'lat':this.pointA.lat,'lng':this.pointA.lng } })
              }
            }else if(item.taskCode === 'marketingTask'){
              this.$router.push({ name:'marketing', query: { 'taskNo': item.taskNo, 'stationNo': item.stationNo} })
            } else if(item.taskCode === 'masterCareTask'){
              this.$router.push({ name:'masterCare', query: { 'taskNo': item.taskNo, 'stationNo': item.stationNo} })
            }else if(item.taskCode === 'specialTask'){
              this.$router.push({ name:'specialTask', query: { 'taskNo': item.taskNo, 'stationNo': item.stationNo} })
            }else {
              let routerName
              if (item.taskCode === 'inspectTask') routerName = 'qcInfoList' // 巡检
              if (item.taskCode === 'stationStandardization') routerName = 'standardTaskList' // 标准化
              this.$router.push({ name: routerName, query: { 'taskNo': item.taskNo, 'stationNo': item.stationNo ,'lat':this.pointA.lat,'lng':this.pointA.lng } })
            }
          }
        })
      },
      toGetMyTaskList () {
        console.log("this.targetDate:",this.targetDate)
        getMyTaskList({
          'pageIndex': this.pageIndex.toString(),
          'pageSize': this.pageSize,
          'taskStatus': 'unfinished',
          'targetDate': this.targetDate,
          'taskCode':  this.flag ===4 ?'specialTask':this.flag ===3 ? 'masterCareTask': this.flag ===2 ? 'marketingTask':this.flag ? 'stationStandardization' : 'inspectTask',
          'conditions': this.conditions,
          'deptNo':this.deptNo
        }).then(res => {
          // 加载状态结束
          this.loading = false;
          if (res.code == 200) {
            if (res.data.businessCode && res.data.businessCode === 200) {
              this.finished = !res.data.pageModel.hasNextPage;

              if (this.pageIndex == 1) {
                this.infoList = res.data.pageModel.list;
              } else {
                this.infoList = this.infoList.concat(res.data.pageModel.list);
              }
              if (!this.finished) {
                this.pageIndex++;
              }
            } else {
              this.error = true;
            }
          } else {
            this.error = true;
          }
        }).catch(() => {
          this.error = true;
        })
      },
      onLoad () {
        this.toGetMyTaskList();
      },
      onRefresh () {
        this.infoList = [];
        this.isLoading = false;
        this.loading = true;
        this.finished = false;
        this.error = false;
        this.pageIndex = 1;
        this.onLoad();
      }

    }
  }
</script>

<style lang="less" scoped>
  .index {
    padding-top: 20px;
    background: #f5f5f5;
    font-size: 0.3rem;
    .flex {
      display: flex;
    }
    .quick_door {
      margin: 0 0.2rem;
      background-color: white;
      height: 2rem;
      border-radius: 5px;
    }
  }
</style>
