
<template>
    <div class="index" >
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh"  >
            <div style="padding-bottom: 55px;" >
                <van-list :error.sync="error"
                          error-text="请求失败，点击重新加载"
                          v-model="loading"
                          :finished="finished"
                          finished-text="没有更多了"
                          @load="onLoad"
                          ref="scrollNews"
                >
                    <div v-for="(item,index) in infoList" :key="index" >
                        <completeTaskCell :info="item"  @click.native="clickNews(item)" :flag="flag"> </completeTaskCell>
                    </div>

                </van-list>
            </div>
        </van-pull-refresh>

    </div>
</template>

<script>
    import completeTaskCell from "./completeTaskCell";
    import {getMyTaskList} from "@/utils/api";
    export default {
        name: "completeTask",
        components:{completeTaskCell},
        props:['active', 'targetDate', 'flag','conditions','deptNo'],
        watch:{
            active(newValue){
                if (newValue==1){
                    this.toSetScrollTop()
                    this.infoList = []
                    this.pageIndex= 1
                    this.toGetMyTaskList()
                }
            },
            targetDate(newValue){
                if(this.active == 1){
                    this.infoList = []
                    this.pageIndex= 1
                    this.toGetMyTaskList()
                }
            },

            flag(){
                if(this.active == 1){
                    this.infoList = []
                    this.pageIndex= 1
                    this.toGetMyTaskList()
                }
            },
            deptNo(){
                if(this.active == 1){
                    this.infoList = []
                    this.pageIndex= 1
                    this.toGetMyTaskList()
                }
            },
            conditions(val) {
                this.toGetMyTaskList()
            }
        },
        data(){
            return {
                topList:[],
                infoList:[],
                isLoading: false,
                loading: false,
                finished: false,
                pageIndex: 1,
                pageSize:'10',
                error:false,
                listHeight:null,
                scrollTop:null
            }
        },
        activated(){
        },
        deactivated() {
            window.removeEventListener('scroll', this.handleScroll,true);
        },
        mounted() {
            window.addEventListener("scroll", this.handleScroll,true);
        },
        destroyed() {
            window.removeEventListener('scroll', this.handleScroll,true)
        },
        methods: {
            handleScroll() {
                this.$nextTick(() => {
                    if( this.$refs.scrollNews.scroller.scrollTop != undefined ){
                        if (this.active == 1){
                            this.scrollTop = this.$refs.scrollNews.scroller.scrollTop
                        }
                    }
                })
            },
            toSetScrollTop(){
                this.$refs.scrollNews.scroller.scrollTop = this.scrollTop
            },
            clickNews(item){
                if(item.taskCode === 'marketingTask'){
                    this.$router.push({ name:'marketing', query: { 'taskNo': item.taskNo, 'stationNo': item.stationNo} })
                }
                if(item.taskCode === 'stationStandardization'){
                    this.$router.push({name:'standardTaskList',query:{'taskNo':item.taskNo,'stationNo': item.stationNo,'detail':'1'}})
                }
                if(item.taskCode === 'inspectTask'){
                    this.$router.push({name:'qcInfoList',query:{'taskNo':item.taskNo,'stationNo': item.stationNo,'detail':'1'}})
                }
                if(item.taskCode === 'masterCareTask'){
                    this.$router.push({name:'masterCare',query:{'taskNo':item.taskNo,'stationNo': item.stationNo}})
                }
                if(item.taskCode === 'specialTask'){
                    this.$router.push({name:'specialTask',query:{'taskNo':item.taskNo,'stationNo': item.stationNo}})
                }
            },
            toGetMyTaskList(){
                console.log("this.targetDate:",this.targetDate)
                getMyTaskList({
                    'pageIndex':this.pageIndex.toString(),
                    'pageSize':this.pageSize,
                    'taskStatus':'finished',
                    'targetDate': this.targetDate,
                    'taskCode':  this.flag ===4 ?'specialTask':this.flag ===3 ? 'masterCareTask': this.flag ===2 ? 'marketingTask':this.flag ? 'stationStandardization' : 'inspectTask',
                    'conditions': this.conditions,
                    'deptNo':this.deptNo
                }).then(res=>{
                    // 加载状态结束
                    this.loading = false;
                    if (res.code == 200){
                        if (res.data.businessCode && res.data.businessCode === 200) {
                            this.finished = !res.data.pageModel.hasNextPage;

                            if (this.pageIndex == 1){
                                this.infoList = res.data.pageModel.list;
                            } else {
                                this.infoList = this.infoList.concat(res.data.pageModel.list);
                            }
                            if (!this.finished){
                                this.pageIndex++;
                            }
                        }else {
                            this.error = true;
                        }
                    }else {
                        this.error = true;
                    }
                }).catch(()=>{
                    this.error = true;
                })
            },
            onLoad() {
                this.toGetMyTaskList();
            },
            onRefresh() {
                this.infoList=[];
                this.isLoading = false;
                this.loading = true;
                this.finished=false;
                this.error = false;
                this.pageIndex = 1;
                this.onLoad();
            }

        }
    }
</script>

<style lang="less" scoped>
    .index{
        padding-top: 20px;
        background:#F7F8F9;
        font-size: 0.3rem;
        width: 100%;
        height: 100%;
        .flex{
            display: flex;
        }
    }
</style>
