
<template>
  <div class="index">
    <div style="background: #fff">
      <div class="search-wrap flex" @click="searchClick">
        <div style="display:flex; align-items: center">
          <van-icon size="0.3rem" name="search" /><span>{{value}}</span>
        </div>
        <van-icon size="0.3rem" name="close" v-show="conditions" @click.stop="clearClick"/>
      </div>
    </div>
    <div class="flex align_items_center justify_content_space_around div-bg">
      <van-dropdown-menu active-color="#3F7C53">
        <van-dropdown-item v-model="flag" :options="taskType" @change="switchClick"/>
      </van-dropdown-menu>
      <van-dropdown-menu active-color="#3F7C53">
        <van-dropdown-item v-model="targetDate"  :options="dateList" @change="dateChange"/>
      </van-dropdown-menu>
      <div @click="showDeptPicker = true" class="flex align_items_center">
        {{deptNoText}}
        <i class="icon-triangle"/>
      </div>
    </div>

    <van-popup v-model="showDeptPicker" position="bottom">
      <van-cascader
              title="请选择运营部"
              :options="deptList"
              @close="showDeptPicker = false"
              active-color="#3f7c53"
              :field-names="deptNames"
              @finish="deptChange"
      />
    </van-popup>


    <van-tabs v-model="active" sticky color="#3F7C53" background="#ffffff" title-inactive-color="#999999" title-active-color="#3F7C53">
      <van-tab title="待完成" :badge="countObj.unfinishedCnt ? countObj.unfinishedCnt : ''">
        <pendingTask class="task_view" :active="active"
                     :flag="flag" :conditions="conditions" :deptNo="deptNo"> </pendingTask>
      </van-tab>
      <van-tab title="已完成" :badge="countObj.finishedCnt ? countObj.finishedCnt : ''">
        <completeTask class="task_view" :active="active" :targetDate="targetDate"
                      :flag="flag" :conditions="conditions" :deptNo="deptNo"> </completeTask>
      </van-tab>
      <van-tab title="已关闭" :badge="countObj.closeCnt && flag ? countObj.closeCnt: ''">
        <closeTask class="task_view" :active="active"
                   :flag="flag" :conditions="conditions" :deptNo="deptNo"> </closeTask>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
  import common from "@/utils/common";
  import moment from "moment";
  import pendingTask from "./subViews/pendingTask";
  import completeTask from "./subViews/completeTask";
  import closeTask from "./subViews/closeTask";
  import {uwStatisticAction} from "../../utils/util";
  import {getMyTaskCount,getMyDeptTree,getMyAreaTree} from '@/utils/api';
  export default {
    name: "myTask",
    components:{pendingTask,completeTask,closeTask},
    data(){
      return {
        active:null,
        deptNo: '',
        province: '',
        city: '',
        county: '',
        switchbzh: require('@/assets/img/task/switchbzh.png'),
        switchxj: require('@/assets/img/task/switchxj.png'),
        flag: true,
        tempToken: '',
        taskCountArr: [],
        countObj:{
          unfinishedCnt:'',
          finishedCnt: '',
          closeCnt: ''
        },
        value:'',
        conditions: '',
        deptNames:{
          text: 'deptName',
          value: 'deptNo',
          children: 'child',
        },
        deptList:[],
        showDeptPicker:false,
        taskType:[
          {text:'标准化任务',value:true},
          {text:'巡检任务',value:false},
          {text:'营销活动',value:2},
         // {text:'站长生日关怀',value:3},
          {text:'专项检查任务',value:4}
        ],
        deptNoText:'全部',

        addressShow:false,
        areaList:[],
        areaNames:{
          text: 'areaName',
          value: 'areaCode',
          children: 'child',
        },
        areaVal:"全部",
        dateVal:"全部",
        dateShow:false,
        targetDate:'',
        dateList:[
          {text:'全部',
            value:''},
          {text:moment().format('YYYY/MM'),value: moment().format('YYYY/MM')},
          {text:moment().subtract(1,'months').format('YYYY/MM'),
            value:moment().subtract(1,'months').format('YYYY/MM')},
          {text:moment().subtract(2,'months').format('YYYY/MM'),
            value:moment().subtract(2,'months').format('YYYY/MM')},
          {text:moment().subtract(3,'months').format('YYYY/MM'),
            value:moment().subtract(3,'months').format('YYYY/MM')},

        ]
      }
    },
    activated(){},
    deactivated() {},
    created() {
      this.getDeptList();
    },
    mounted() {
      this.$route.query.active ? this.active = this.$route.query.active : this.active = 0
      if(this.$route.query.conditions){
        this.value = this.$route.query.conditions
        this.conditions = this.$route.query.conditions
      }else{
        this.value = '请输入站点名称或运营人员姓名'
        this.conditions = ''
      }
      uwStatisticAction('/task/myTask','我的任务')
      this.tempToken = this.$route.query.jumpFrom != undefined ? this.$route.query.token : common.getItem('wtToken')
      this.togetMyTaskCount()
    },
    destroyed() {},
    methods: {
      //处理数据
      getDeptList(){
        getMyDeptTree({token: common.getItem('wtToken')}).then(res=>{
          if (res.code ===200 && res.data.businessCode === 200) {
            this.deptList = this.getTreeData(res.data.data);
          }
        })
      },
      getTreeData(data) {
        for (var i = 0; i < data.length; i++) {
          if (data[i].child.length < 1) {
            // children若为空数组，则将children设为undefined
            data[i].child = undefined;
          } else {
            // children若不为空数组，则继续 递归调用 本方法
            this.getTreeData(data[i].child);
          }
        }
        return data;
      },
      //运营部选择
      deptChange(value){
        let arr = value.selectedOptions;
        for(let i= 0; i < arr.length; i++) {
          if(arr[i].deptName === "全部" && i > 0 ) {
            this.deptNoText = arr[i-1].deptName
          }else{
            this.deptNoText = arr[i].deptName
          }
        }
        this.deptNo = value.selectedOptions.length > 0 ? value.selectedOptions[value.selectedOptions.length-1].deptNo:'';
        this.togetMyTaskCount()
      },
      dateChange(){
        this.togetMyTaskCount();
      },
      clearClick() {
        this.value = '请输入站点名称或运营人员姓名'
        this.conditions = ''
        this.togetMyTaskCount()
      },
      searchClick() {
        this.$router.push({name: 'taskSearch'})
      },
      togetMyTaskCount() {
        getMyTaskCount({
          token: this.tempToken,
          conditions: this.conditions,
          targetDate: this.targetDate,
          deptNo:this.deptNo
        }).then(res=>{
          if (res.code == 200) {
            if (res.data && res.data.businessCode == 200) {
              this.taskCountArr = res.data.data
              for(let obj of this.taskCountArr){
                if (this.flag === 2){
                  if(obj.taskCode == 'marketingTask'){
                    this.countObj = obj
                  }
                }else  if (this.flag === 3){
                  if(obj.taskCode == 'masterCareTask'){
                    this.countObj = obj
                  }
                }else  if (this.flag === 4){
                  if(obj.taskCode == 'specialTask'){
                    this.countObj = obj
                  }
                }else if (this.flag){
                  if(obj.taskCode == 'stationStandardization'){
                    this.countObj = obj
                  }
                } else {
                  if(obj.taskCode == 'inspectTask'){
                    this.countObj = obj
                  }
                }
              }
            }
          }
        })
      },
      switchClick() {
        // this.flag = !this.flag
        this.togetMyTaskCount()
      },
    },
    beforeRouteLeave (to, from, next) {
      if ( to.name == 'newsDeatail'){
      } else  {
        this.$store.dispatch('keepAliveState/removeKeepAlive', 'myTask')
      }
      next()
    },
  }
</script>

<style lang="less" scoped>
  .index{
    height: 100%;
    background:#f5f5f5;
    font-size: 0.3rem;
    .flex{
      display: flex;
    }
    .search-wrap{
      height: 0.6rem;
      line-height: 0.6rem;
      padding: 0 0.24rem;
      border-radius: 0.3rem;
      background: #F0F0F0;
      margin: 0.24rem 0.1rem 0;
      font-size: 0.26rem;
      color: #999999;
      align-items: center;
      justify-content: space-between;
      span{
        margin-left:0.1rem;
      }
    }
    .top-wrap{
      justify-content: space-between;
      align-items: center;
      padding: 0.3rem 0 0.1rem 0.3rem;
      background: #fff;
      .left{
        div{
          &:nth-child(1){
            font-size: 0.48rem;
            color: #4A4A4A;
          }
          &:nth-child(2){
            font-size: 0.24rem;
            color: #9B9B9B;
          }
        }
      }
      .right{

      }
      .switch-img{
        height: 0.56rem;
      }
    }
    .header_task{
      width: 100%;
      height: 0.8rem;
      line-height: 0.8rem;
      text-align: center;
      background: transparent;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .div-bg{
    background: #ffffff;
  }
  /deep/.van-dropdown-menu__title::after{
    border-color: transparent transparent #3F7C53 #3F7C53 !important;
  }
  .icon-triangle{
    width: 0;
    height: 0;
    border-left: 0.08rem solid transparent;
    border-right: 0.08rem solid transparent;
    border-top: 0.1rem solid #3F7C53;
    margin-left: 0.1rem;
  }
  /deep/.van-dropdown-menu__bar{
    box-shadow:none;
  }
</style>

