
<template>
    <div class="bg">
      <div class="top_info">
        <div>
          <div class="name">{{info.stationName}}</div>
          <div class="desc">管理责任人：{{info.marketerName}}</div>
        </div>
        <div class="flex align_items_center" v-if="flag && (flag!==2&&flag!==3&&flag!==4)">
          <div class="detail_btn" v-if="info.uniqueNo == myUniqueNo">去完成</div>
          <div class="detail_btn" v-else>帮完成</div>
        </div>
      </div>
      <div class="line"></div>
      <div class="time">{{'最晚完成时间：'+info.expectTime.split(' ')[0]}}</div>
    </div>
</template>

<script>
    export default {
        name: "closeTaskCell",
        props:['info','myUniqueNo','flag']
    }
</script>

<style lang="less" scoped>
    .bg{
        margin: 0 0.2rem 0.2rem 0.2rem;
        padding: 20px 0.3rem 0 0.3rem ;
        border-radius: 4px;
        background-color: white;
        .top_info{
            display: flex;
            justify-content: space-between;
            .name{
                font-weight: bold;font-size: 15px;
            }
            .desc{
                display: flex;font-size: 13px;color: #999999;
                margin-top: 8px ;
            }
            .pass_image{
                width: 44px;height: 44px
            }
            .detail_btn{
                width: 60px;height: 24px;
                background-color:#3F7C53 ;
                border-radius: 12px;
                font-size: 12px;
                color: #FFFFFF;
                text-align: center;
                line-height: 24px;
            }
        }
        .line{
            background-color: #EFEFF0;height: 1px;margin-top: 8px
        }
        .time{
            font-size: 12px;color: #999999;padding: 8px 0
        }
    }

</style>
