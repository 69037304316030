
<template>
    <div class="bg">
        <div class="top_info">
            <div>
                <div class="name">{{info.stationName}}</div>
                <div class="desc">管理责任人：{{info.marketerName}}</div>
            </div>
            <div  class="flex align_items_center" v-if="info.taskCode == 'inspectTask' && info.taskStatus == 'finished'">
                <div class="detail_btn">去查看</div>
            </div>
            <div class="flex align_items_center" v-if="flag===2||flag===3||flag===4||info.approve == 'AGREE'">
                <div class="detail_btn">去查看</div>
            </div>
        </div>
        <div class="time">{{'提交人：'+info.commitUser}}</div>
        <div class="time">{{'完成时间：'+info.finishTime}}</div>
    </div>
</template>

<script>
    export default {
        name: "completeTaskCell",
        props:['info','flag']
    }
</script>

<style lang="less" scoped>
    .bg{
        margin: 0 0.2rem 0.2rem 0.2rem;
        padding: 20px 0.3rem 0 0.3rem ;
        border-radius: 4px;
        background-color: white;
        .top_info{
            display: flex;
            justify-content: space-between;
            .name{
                font-weight: bold;
                font-size: 15px;
            }
            .desc{
                display: flex;
                font-size: 13px;
                color: #999999;
                margin-top: 8px ;
            }
            .pass_image{
                width: 44px;
                height: 39px
            }
        }
        .line{
            background-color: #EFEFF0;
            height: 1px;
            margin-top: 8px
        }
        .time{
            font-size: 12px;
            color: #999999;
            padding: 8px 0
        }
        .detail_btn{
            width: 60px;height: 24px;
            background-color:#3F7C53 ;
            border-radius: 12px;
            font-size: 12px;
            color: #FFFFFF;
            text-align: center;
            line-height: 24px;
        }
    }

</style>
