<template>
  <div class="bg">
    <div class="top_info">
      <div>
        <div class="name">{{info.stationName}}<img :src="overtimeIcon" class="overtime-img" v-if="showFlag"></div>
        <div class="desc">管理责任人：{{info.marketerName}}</div>
        <div v-if="info.approve == 'REJECT'" class="not_pass flex" >审核不通过：{{info.comment}}</div>
      </div>
      <div class="flex align_items_center" >
        <div class="detail_btn" v-if="info.uniqueNo === myUniqueNo">去完成</div>
        <div class="detail_btn" v-else>帮完成</div>
      </div>
    </div>
    <div class="line"></div>
    <div class="time" v-if="info.taskCode === 'masterCareTask'">{{'站长生日：'+info.expectTime.split(' ')[0]+'-'+info.masterName}}</div>
    <div class="time" v-else>{{'最晚完成时间：'+info.expectTime.split(' ')[0]}}</div>
  </div>
</template>

<script>
  export default {
    name: "pendingTaskCell",
    props:['info','myUniqueNo','flag','stationNo'],
    data() {
      return {
        overtimeIcon: require('@/assets/img/task/overtimeicon.png'),
        showFlag: false,
      }
    },
    mounted() {
      this.$nextTick(()=>{
        let timeFlag = new Date().getTime() > new Date(this.info.expectTime).getTime() ? true : false
        this.showFlag = (!this.flag && timeFlag ) ? true : false
      })
    },
    methods:{
    }
  }
</script>

<style lang="less" scoped>
  figure{
    margin:0;
  }
  .bg{
    margin: 0 0.2rem 0.2rem 0.2rem;
    padding: 20px 0.3rem 0 0.3rem ;
    border-radius: 4px;
    background-color: white;
    .overtime-img{
      width: 0.7rem;
      height: 0.32rem;
      margin-left: 0.1rem;
      margin-top: -0.05rem;
    }
    .top_info{
      display: flex;
      justify-content: space-between;
      .name{
        font-weight: bold;
        font-size: 15px;
        align-items: center;
        span{
          margin-right: 0.16rem;
        }
      }
      .desc{
        display: flex;font-size: 13px;color: #999999;
        margin-top: 8px ;
      }
      .detail_btn{
        width: 60px;height: 24px;
        background-color:#3F7C53 ;
        border-radius: 12px;
        font-size: 12px;
        color: #FFFFFF;
        text-align: center;
        line-height: 24px;
      }
    }
    .line{
      background-color: #EFEFF0;
      height: 1px;margin-top: 8px
    }
    .time{
      font-size: 12px;
      color: #999999;
      padding: 8px 0
    }
    .not_pass{
      margin-top: 8px;
      font-size: 13px;
      color: #F4664A;
    }
  }
</style>
