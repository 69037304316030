
<template>
    <div class="index" >
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh"  >
            <div style="padding-bottom: 55px;margin: 0" >
                <van-list :error.sync="error"
                          error-text="请求失败，点击重新加载"
                          v-model="loading"
                          :finished="finished"
                          finished-text="没有更多了"
                          @load="onLoad"
                          ref="scrollNews"
                >
                    <div v-for="(item,index) in infoList" :key="index" >
                        <closeTaskCell :info="item" @click.native="clickNews(item)" :myUniqueNo="myUniqueNo" :flag="flag"></closeTaskCell>
                    </div>
                </van-list>
            </div>
        </van-pull-refresh>

    </div>
</template>

<script>
    import closeTaskCell from "./closeTaskCell";
    import {getMyTaskList} from "@/utils/api";
    import common from "@/utils/common";
    export default {
        name: "closeTask",
        components:{closeTaskCell},
        props:['active', 'flag', 'conditions','deptNo'],
        watch:{
          active(newValue){
            if (newValue==2){
              this.toSetScrollTop()
              this.infoList = []
              this.pageIndex= 1
              this.toGetMyTaskList()
            }
          },
          flag(){
            if(this.active == 2){
              this.infoList = []
              this.pageIndex= 1
              this.toGetMyTaskList()
            }
          },
          deptNo(){
            if(this.active == 2){
                this.infoList = []
                this.pageIndex= 1
                this.toGetMyTaskList()
            }
          },
          conditions(val) {
            this.toGetMyTaskList()
          }
        },
        data(){
            return {
                topList:[],
                infoList:[],
                isLoading: false,
                loading: false,
                finished: false,
                pageIndex: 1,
                pageSize:'10',
                error:false,
                listHeight:null,
                scrollTop:null,
                myUniqueNo: '',
            }
        },
        activated(){
        },
        deactivated() {
            window.removeEventListener('scroll', this.handleScroll,true);
        },
        mounted() {
            window.addEventListener("scroll", this.handleScroll,true);
            this.myUniqueNo = common.getItem('uniqueNo')
        },
        destroyed() {
            window.removeEventListener('scroll', this.handleScroll,true)
        },
        methods: {
            handleScroll() {
                this.$nextTick(() => {
                    if( this.$refs.scrollNews.scroller.scrollTop != undefined ){
                        if (this.active == 2){
                            this.scrollTop = this.$refs.scrollNews.scroller.scrollTop
                        }
                    }
                })
            },
            toSetScrollTop(){
                this.$refs.scrollNews.scroller.scrollTop = this.scrollTop
            },
            clickNews(item){
              if(!this.flag) return
              let routerName
              if (item.taskCode == 'inspectTask') routerName = 'qcInfoList' // 巡检
              if (item.taskCode == 'stationStandardization') routerName = 'standardTaskList' // 标准化
              this.$router.push({ name: routerName, query: { 'taskNo': item.taskNo, 'stationNo': item.stationNo } })
            },
            toGetMyTaskList(){
                getMyTaskList({
                    'pageIndex':this.pageIndex.toString(),
                    'pageSize':this.pageSize,
                    'taskStatus':'closed',
                    'taskCode':  this.flag ===4 ?'specialTask':this.flag ===3 ? 'masterCareTask': this.flag ===2 ? 'marketingTask':this.flag ? 'stationStandardization' : 'inspectTask',
                    'conditions': this.conditions,
                    'deptNo':this.deptNo
                }).then(res=>{
                    // 加载状态结束
                    this.loading = false;
                    if (res.code == 200){
                        if (res.data.businessCode && res.data.businessCode === 200) {
                            this.finished = !res.data.pageModel.hasNextPage;
                            if (!this.finished){
                                this.pageIndex++;
                            }
                            if (this.pageIndex == 1){
                                this.infoList = res.data.pageModel.list;
                            } else {
                                this.infoList = this.infoList.concat(res.data.pageModel.list);
                            }
                        }else {
                            this.error = true;
                        }
                    }else {
                        this.error = true;
                    }
                }).catch(()=>{
                    this.error = true;
                })
            },
            onLoad() {
                this.toGetMyTaskList();
            },
            onRefresh() {
                this.infoList=[];
                this.isLoading = false;
                this.loading = true;
                this.finished=false;
                this.error = false;
                this.pageIndex = 1;
                this.onLoad();
            }

        }
    }
</script>

<style lang="less" scoped>
    .index{
        padding-top: 20px;
        background:#f5f5f5;
        font-size: 0.3rem;
        .flex{
            display: flex;
        }
        .quick_door{
            margin: 0 0.2rem;
            background-color: white;
            height: 2rem;
            border-radius:5px;
        }
    }
</style>
